import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import tireModels from './modules/tire/tireModels';
import tireReviews from './modules/tire/tireReviews';
import tireReview from './modules/tire/tireReview';
import tireModel from './modules/tire/tireModel';
import tireModelEdit from './modules/tire/tireModelEdit';
import statistics from './modules/statistics';
import ui from './modules/ui';
import history from './modules/history';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    history,
    tireModels,
    tireReviews,
    tireReview,
    tireModel,
    tireModelEdit,
    statistics,
    ui,
  },
});
