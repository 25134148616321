import Vue from 'vue';
import VueRouter from 'vue-router';
import authState from '@/store/modules/auth';
import Login from '../views/Login';

// Tabs
const DetalInfo = () => import('@/views/TireModel/Tabs/DetalInfo');

const tireReviews = () => import('@/views/TireModel/Tabs/TireReviews');
const tireReviewsList = () => import('@/views/TireModel/Tabs/TireReviews/TireReviewsList.vue');
const tireReview = () => import('@/views/TireModel/Tabs/TireReviews/TireReview.vue');

const Structure = () => import('@/views/TireModel/Tabs/Structure');

const TireModelEdit = () => import('@/views/TireModel/Tabs/Edit');

const Tires = () => import('@/views/TireModel/Tabs/Tires');
const Tire = () => import('@/views/Tire/Tire.vue');

const NewsList = () => import('@/views/News/NewsList.vue');
const CreatingNews = () => import('@/views/News/CreatingNews.vue');
const EditingNews = () => import('@/views/News/EditingNews.vue');

// Left menu
const UserList = () => import('@/views/Users/UserList.vue');
const User = () => import('@/views/Users/User.vue');

const Statistics = () => import('@/views/Statistics');
const AutocodeReport = () => import('@/views/Statistics/AutoсodeReport');

const TireModelsList = () => import('@/views/TireModels/TireModelsList.vue');
const TireModel = () => import('@/views/TireModel');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/tire-models',
  },
  {
    path: '/tire-models/:tire_model_id',
    component: TireModel,
    children: [
      {
        path: '/',
        name: 'DetalInfo',
        component: DetalInfo,
      },
      {
        path: 'reviews',
        component: tireReviews,
        children: [
          {
            path: ':tire_review_id',
            name: 'tireReview',
            component: tireReview,
          },
          {
            path: '/',
            name: 'tireReviewsList',
            component: tireReviewsList,
          },
        ],
      },
      {
        path: 'tires',
        name: 'Tires',
        component: Tires,
      },
      {
        path: 'structure',
        name: 'Structure',
        component: Structure,
      },
      {
        path: 'edit',
        name: 'Edit',
        component: TireModelEdit,
      },
    ],
  },
  {
    path: '/tire-models',
    name: 'TireModelsList',
    component: TireModelsList,
  },
  {
    path: '/statistics',
    component: Statistics,
    children: [
      {
        path: '/',
        name: 'statistics-home',
        redirect: 'autocode-report',
      },
      {
        path: 'autocode-report',
        name: 'AutocodeReport',
        component: AutocodeReport,
      },
    ],
  },
  {
    path: '/tire/:tire_id',
    name: 'Tire',
    component: Tire,
  },
  {
    path: '/news-edit/:news_id',
    name: 'EditingNews',
    component: EditingNews,
  },
  {
    path: '/news-list',
    name: 'NewsList',
    component: NewsList,
  },
  {
    path: '/creating-news',
    name: 'CreatingNews',
    component: CreatingNews,
  },
  {
    path: '/user-list',
    name: 'UserList',
    component: UserList,
  },
  {
    path: '/user/:user_id',
    name: 'User',
    component: User,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.name !== 'Login' && !authState.state().token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
