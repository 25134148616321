import { getRequest, postRequest } from '@/utils/apiHelper';

export default {
  state: () => ({
    getTireModelRequestStatus: 'none',
    saveTireModelRequestStatus: 'none',
    tireModelEditData: {
      tireModelInfo: {},
      season: {
        id: 1,
      },
      options: {
        purpose: {},
        season: {},
        summerType: {},
        technology: {},
        treadPattern: {},
        winterType: {},
      },
    },
  }),
  mutations: {
    setTireModelEditData(state, payload) {
      state.tireModelEditData = payload;
    },
    setTireModelGetStatus(state, payload) {
      state.getTireModelRequestStatus = payload;
    },
    setTireModelSaveStatus(state, payload) {
      state.saveTireModelRequestStatus = payload;
    },
  },
  actions: {
    async getTireModelEditData({ commit }, id) {
      try {
        commit('setTireModelGetStatus', 'requested');
        const { data } = await getRequest(`api/v1/admin/tire-models/${id}/edit`);
        console.log(data);
        commit('setTireModelEditData', data);
        commit('setTireModelGetStatus', 'success');
      } catch (e) {
        commit('setTireModelGetStatus', 'failed');
        console.error(e);
      }
    },
    async saveTireModelEditData({ commit }, { id, tireModelData }) {
      try {
        commit('setTireModelSaveStatus', 'requested');
        await postRequest(`api/v1/admin/tire-models/${id}/edit`, tireModelData);
        commit('setTireModelSaveStatus', 'success');
      } catch (e) {
        commit('setTireModelSaveStatus', 'failed');
        console.error(e);
      }
    },
  },
  getters: {
    TIRE_MODEL_EDIT_DATA: (state) => state.tireModelEditData,
    GET_TIRE_MODEL_EDIT_REQUEST_STATUS: (state) => state.getTireModelRequestStatus,
    SAVE_TIRE_MODEL_EDIT_REQUEST_STATUS: (state) => state.saveTireModelRequestStatus,
  },
};
