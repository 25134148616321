import { getRequest } from '@/utils/apiHelper';

export default {
  state: () => ({
    tireModelList: [],
    tireModelRequestStatus: 'none',
  }),
  mutations: {
    setTireModelList(state, payload) {
      state.tireModelList = payload;
    },
    setTireModelRequestStatus(state, payload) {
      state.tireModelRequestStatus = payload;
    },
  },
  actions: {
    async getTireModellist({ commit }) {
      try {
        commit('setTireModelRequestStatus', 'requested');
        const { data: { tireModels } } = await getRequest('api/v1/admin/tire-models');
        commit('setTireModelList', tireModels);
        commit('setTireModelRequestStatus', 'success');
      } catch (e) {
        commit('setTireModelRequestStatus', 'failed');
        console.error(e);
      }
    },
  },
  getters: {
    TIRE_MODEL_LIST: (state) => state.tireModelList,
    TIRE_MODEL_REQUEST_STATUS: (state) => state.tireModelRequestStatus,
  },
};
