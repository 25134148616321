import Vue from 'vue';
import axios from 'axios';

const qs = require('qs');

const baseURL = process.env.VUE_APP_API_URL;

export const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    XMLHttpRequest: 'X-Requested-With',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  // eslint-disable-next-line max-len, no-useless-concat
  const accessToken = document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)')) ? document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)'))[2] : '';

  if (accessToken !== 'undefined') {
    const configWithToken = { ...config, headers: { ...config.headers, Authorization: `Bearer ${decodeURI(accessToken)}` } };
    return configWithToken;
  }
  return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => response,
  (error) => {
    const { response: { status, data: { message } } } = error;

    if (status === 401) {
      Vue.prototype.$cookies.remove('token_');
      return Promise.reject(error);
    }
    const customMessage = 'Неизвестная ошибка. Попробуйте выполнить действие позже.';
    Vue.prototype.$notify({
      title: 'Ошибка',
      message: message || customMessage,
      type: 'error',
    });
    return Promise.reject(error);
  });

export const postRequest = async (url, data = {}, headers = {}, params = {}) => {
  const config = {
    data,
    headers,
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };

  const res = await instance.post(url, data, config);
  return res;
};

export const getRequest = async (url, params = {}) => {
  const config = {
    url,
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };

  const res = await instance.get(url, config);
  return res;
};
