import Vue from 'vue';
import './styles/main.scss';
import './plugins/element.js';
import './plugins/cookies.js';
import dotenv from 'dotenv';
import Editor from 'vue-editor-js/src/index';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store';

dotenv.config();

Vue.use(Editor);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
