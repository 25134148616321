import { getRequest } from '@/utils/apiHelper';

export default {
  state: () => ({
    statistics: {
      notFoundCars: [],
      avtocodReport: [],
    },
    pagination: {
      perPage: 10,
      page: 1,
    },
    statisticsRequestStatus: 'none',
  }),
  mutations: {
    setStatistics(state, payload) {
      state.statistics = payload;
    },
    setStatisticsPagination(state, payload) {
      state.pagination = payload;
    },
    setStatisticsRequestStatus(state, payload) {
      state.statisticsRequestStatus = payload;
    },
  },
  actions: {
    async getStatistics({ commit }, params) {
      try {
        commit('setStatisticsRequestStatus', 'requested');
        const { data: { avtocodReport, notFoundCars, pagination } } = await getRequest('api/v1/admin/statistics/filter-histories', params);
        commit('setStatistics', { avtocodReport, notFoundCars });
        commit('setStatisticsPagination', pagination);
        commit('setStatisticsRequestStatus', 'success');
      } catch (e) {
        commit('setStatisticsRequestStatus', 'failed');
        console.error(e);
      }
    },
  },
  getters: {
    STATISTICS: (state) => state.statistics,
    STATISTICS_PAGINATION: (state) => state.pagination,
    STATISTICS_REQUEST_STATUS: (state) => state.statisticsRequestStatus,
  },
};
