import { getRequest } from '@/utils/apiHelper';

export default {
  state: () => ({
    tireModel: {},
    formatedTireModel: [],
    structure: {},
    requestStructureStatus: 'none',
    requestFormatedTireModelStatus: 'none',
  }),
  mutations: {
    setTireModel(state, payload) {
      state.tireModel = payload;
    },
    setFormatedTireModel(state, payload) {
      const tireModelInfo = [
        {
          key: 'id',
          label: 'ID',
          value: payload.id,
        },
        {
          key: 'name',
          label: 'Название EN',
          value: payload.name,
        },
        {
          key: 'brand',
          label: 'Бренд',
          value: payload.tireModelBrand.name,
        },
        {
          key: 'yandexId',
          label: 'Яндекс ID',
          value: payload.yandexId,
        },
        {
          key: 'season',
          label: 'Сезон',
          value: payload.season.name,
        },
        {
          key: 'slug',
          label: 'URL',
          value: `/catalog/${payload.slug}`,
        },
      ];
      state.formatedTireModel = tireModelInfo;
    },
    setTireModelId(state, payload) {
      state.tireModel.id = payload;
    },
    setStructure(state, payload) {
      state.structure = payload;
    },
    setStructureRequestStatus(state, payload) {
      state.requestStructureStatus = payload;
    },
    setFormatedTireModelRequestStatus(state, payload) {
      state.requestFormatedTireModelStatus = payload;
    },
  },
  actions: {
    async getTireModel({ commit }, id) {
      try {
        commit('setFormatedTireModelRequestStatus', 'requested');
        const { data } = await getRequest(`api/v1/admin/tire-models/${id}`);
        commit('setTireModel', data);
        commit('setFormatedTireModel', data);
        commit('setFormatedTireModelRequestStatus', 'success');
      } catch (e) {
        commit('setFormatedTireModelRequestStatus', 'failed');
        console.error(e);
      }
    },
    async getStructure({ commit }, id) {
      try {
        commit('setStructureRequestStatus', 'requested');
        const { data: { tireModel } } = await getRequest(`api/v1/admin/structure/tire-models/${id}`);
        commit('setStructure', tireModel);
        commit('setStructureRequestStatus', 'success');
      } catch (e) {
        commit('setStructureRequestStatus', 'failed');
        console.error(e);
      }
    },
  },
  getters: {
    STRUCTURE: (state) => state.structure,
    STRUCTURE_REQUEST_STATUS: (state) => state.requestStructureStatus,
    TIRE_MODEL: (state) => state.tireModel,
    FORMATED_TIRE_MODEL: (state) => state.formatedTireModel,
    FORMATED_TIRE_MODEL_REQUEST_STATUS: (state) => state.requestFormatedTireModelStatus,
  },
};
