<template>
  <div class="header-container">
    <div class="logo">
      <img
        src="@/assets/images/text-logo.svg"
        alt="">
      <div class="logo-title">
        административная панель
      </div>
    </div>
    <el-menu
      default-active="1"
      class="header"
      mode="horizontal"
      text-color="#000"
      background-color="#ffffff"
      active-text-color="#000">
      <el-submenu index="1">
        <template slot="title">
          <div class="demo-basic--circle">
            <div class="block">
              <el-avatar
                size="medium"
                src="https://sun9-25.userapi.com/c853528/v853528600/1baee3/HbWkQpGT-gA.jpg" />
            </div>
          </div>
        </template>
        <el-menu-item
          index="1-1"
          @click="logout">
          Выйти <i class="el-icon-right" />
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppHeader',
  methods: {
    ...mapActions(['logoutUserAction']),
    logout() {
      this.$router.push('/login');
      this.logoutUserAction();
    },
  },
};
</script>

<style lang="scss">
.header-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #ffffff;
  .el-submenu__title {
    display: flex;
    align-items: center;
  }
  &::before, &::after {
    content: none !important;
  }
  .logo {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    margin-right: 20px;
    padding-bottom: 0px;
    .logo-title {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-size: 10px;
      line-height:10px;
      letter-spacing: 2.2px;
      height: auto;
      margin-left: 10px;
      margin-top: 2px;
      padding-bottom: 2px;
    }
    img {
      height: 22px;
    }
  }
}
</style>
