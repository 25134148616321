import { getRequest, postRequest } from '@/utils/apiHelper';

export default {
  state: () => ({
    tireReviews: {
      site: [],
      yandex: [],
    },
    tireReviewsPagination: {
      site: {
        perPage: 20,
        page: 1,
      },
      yandex: {
        perPage: 20,
        page: 1,
      },
    },
    tireReviewsRequestStatus: 'none',
    tireEditReviewsRequestStatus: 'none',
  }),
  mutations: {
    setTireReviews(state, { tireReviews, from }) {
      state.tireReviews[from] = tireReviews;
    },
    setTireReviewsPagination(state, { from, pagination }) {
      state.tireReviewsPagination[from] = pagination;
    },
    setTireReviewsRequestStatus(state, payload) {
      state.tireReviewsRequestStatus = payload;
    },
    setTireEditReviewsRequestStatus(state, payload) {
      state.tireEditReviewsRequestStatus = payload;
    },
  },
  actions: {
    async getTireReviewsList({ commit }, { id, params, from }) {
      try {
        commit('setTireReviewsRequestStatus', 'requested');
        const { data: { pagination, tireReviews } } = await getRequest(`api/v1/admin/tire-models/reviews/${id}`, { ...params, from });
        commit('setTireReviewsPagination', { pagination, from });
        commit('setTireReviews', { tireReviews, from });
        commit('setTireReviewsRequestStatus', 'success');
      } catch (e) {
        commit('setTireReviewsRequestStatus', 'faild');
        throw e;
      }
    },

    async setTireReviewsDescription({ commit }, payload) {
      commit('setTireEditReviewsRequestStatus', 'requested');
      try {
        await Promise.all(payload.map((review) => {
          const reqReview = {
            rating: review.rating,
            contentPlus: review.contentPlus,
            contentMinus: review.contentMinus,
            contentComment: review.contentComment,
            authorFio: review.authorFio,
            authorEmail: review.authorEmail,
            sort: review.sort,
            isActive: review.isActive,
          };

          return postRequest(
            `api/v1/admin/tire-models/review/${review.id}`,
            reqReview,
          );
        }));
        commit('setTireEditReviewsRequestStatus', 'success');
      } catch (e) {
        commit('setTireEditReviewsRequestStatus', 'failed');
      }
    },
  },
  getters: {
    TIRE_REVIEWS: (state) => state.tireReviews,
    TIRE_REVIEWS_PAGINATION: (state) => state.tireReviewsPagination,
    TIRE_REVIEWS_REQUEST_STATUS: (state) => state.tireReviewsRequestStatus,
    TIRE_EDIT_REVIEWS_REQUEST_STATUS: (state) => state.tireEditReviewsRequestStatus,
  },
};
