export default {
  state: () => ({
    pageTitle: 'Главная',
  }),
  mutations: {
    setPageTitle(state, payload) {
      state.pageTitle = payload;
    },
  },
  actions: {
  },
  getters: {
    PAGE_TITLE: (state) => state.pageTitle,
  },
};
