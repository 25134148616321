<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
import Layout from '@/components/layout/layout.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Layout,
  },
  computed: {
    ...mapGetters(['TIRE_MODEL_LIST', 'IS_AUTH']),
  },
  watch: {
    IS_AUTH(state) {
      if (state) {
        this.getTireModellist();
      }
    },
  },
  beforeMount() {
    this.setStartHistoryLength(window.history.length);
  },
  async mounted() {
    await this.setIsAuth();
  },

  methods: {
    ...mapMutations(['setIsAuth', 'setStartHistoryLength']),
    ...mapActions(['getTireModellist']),
  },
};
</script>
