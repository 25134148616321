export const checkEmail = (_rule, value, callback) => {
  if (!value) {
    callback(new Error('Введите Email'));
  }
  callback();
};

export const validatePass = (_rule, value, callback) => {
  if (value === '') {
    callback(new Error('Ведите пароль'));
  } else {
    callback();
  }
};

// eslint-disable-next-line max-len
export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
