<template>
  <el-menu
    class="left-menu"
    :router="true"
    :default-active="activeRoutePath">
    <el-menu-item index="/tire-models">
      <i class="el-icon-notebook-2" />
      <span>Модели шин</span>
    </el-menu-item>

    <el-submenu index="2">
      <template slot="title">
        <i class="el-icon-data-analysis" />
        <span>Статистика</span>
      </template>
      <el-menu-item index="/statistics/autocode-report">
        Отчет по автокоду
      </el-menu-item>
    </el-submenu>

    <!-- <el-menu-item index="/news-list">
      <i class="el-icon-postcard"></i>
      <span>Новости</span>
    </el-menu-item>
    <el-menu-item index="/user-list">
      <i class="el-icon-user"></i>
      <span>Пользователи</span>
    </el-menu-item> -->
  </el-menu>
</template>
<script>
export default {
  name: 'LeftMenu',
  computed: {
    activeRoutePath() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss">
.left-menu {
  li {
    text-align: left;
    a {
      color: #000;
      text-decoration: none;
    }
  }
}

</style>
