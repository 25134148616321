<template>
  <el-container v-if="IS_AUTH">
    <el-header>
      <app-header />
    </el-header>
    <el-container>
      <el-aside width="250px">
        <left-menu />
      </el-aside>
      <el-main>
        <div class="page-header">
          <span
            v-if="isShowBackBtn"
            class="back-btn"
            @click="goBack">
            <i class="el-icon-back" /> Назад
          </span>
          <el-divider
            v-if="isShowBackBtn"
            direction="vertical" />
          <span class="title">
            {{ PAGE_TITLE }}
          </span>
        </div>
        <el-card
          shadow="always"
          class="card">
          <router-view />
        </el-card>
      </el-main>
    </el-container>
  </el-container>
  <div v-else>
    <router-view />
  </div>
</template>
<script>
import AppHeader from '@/components/layout/header';
import LeftMenu from '@/components/layout/leftMenu';
import { mapGetters } from 'vuex';

const urlsNoBtn = ['/tire-models', '/statistics/autocode-report'];

export default {
  name: 'Layout',
  components: {
    AppHeader,
    LeftMenu,
  },
  computed: {
    ...mapGetters(['IS_AUTH', 'PAGE_TITLE', 'START_HISTORY_LENGTH']),
    isShowBackBtn() {
      return !urlsNoBtn.includes(this.$route.path);
    },
  },
  methods: {
    goBack() {
      const currentHistorySteps = window.history.length - this.START_HISTORY_LENGTH;
      if (currentHistorySteps === 0) {
        const backRoute = this.$route.fullPath.split('/').filter((v) => v);
        const newBackRoute = backRoute.slice(0, -1).join('/');
        this.$router.replace({ path: `/${newBackRoute}` });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="scss">
.page-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .back-btn {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: #409EFF;
    }
    i {
      font-size: 18px;
      margin-right: 5px;
    }
  }
  .title {
    margin-left: 10px;
    font-size: 18px;
  }
}
</style>
