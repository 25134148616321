export default {
  state: () => ({
    startHistoryLength: 0,
  }),
  mutations: {
    setStartHistoryLength(state, payload) {
      state.startHistoryLength = payload;
    },
  },
  getters: {
    START_HISTORY_LENGTH: (state) => state.startHistoryLength,
  },
};
