<template>
  <div>
    <el-row
      type="flex"
      class="login"
      justify="center"
      align="middle">
      <el-col :span="8">
        <el-form
          ref="loginForm"
          :model="loginForm"
          status-icon
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="loginForm.email"
              type="email"
              autocomplete="on" />
          </el-form-item>
          <el-form-item
            label="Пароль"
            prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              autocomplete="on" />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              :disabled="!isValidFormValues"
              @click="submitForm('loginForm')">
              Войти
            </el-button>
            <el-button @click="resetForm('loginForm')">
              Сбросить
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { checkEmail, validatePass, emailRegExp } from '@/utils/validateUser';

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          { validator: checkEmail, trigger: 'blur' },
          { type: 'email', message: 'Вы ввели некорректный email', trigger: 'blur' },
        ],
        password: [
          { validator: validatePass, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    isValidFormValues() {
      return this.loginForm.password && this.isValidEmail();
    },
  },
  methods: {
    ...mapActions(['loginUserAction']),
    isValidEmail() {
      return this.loginForm.email.match(emailRegExp);
    },
    login() {
      this.loginUserAction(this.loginForm);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
          return true;
        }
        return false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss">
  .login {
    height: 100vh;
  }
</style>
