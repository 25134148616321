import { getRequest, postRequest } from '@/utils/apiHelper';
import has from 'lodash.has';

export default {
  state: () => ({
    tireReview: {},
    editReviewsRequestStatus: 'none',
  }),
  mutations: {
    setTireReview(state, payload) {
      state.tireReview = payload;
    },
    setEditReviewRequestStatus(state, payload) {
      state.editReviewsRequestStatus = payload;
    },
  },
  actions: {
    async getTireReview({ commit }, { id }) {
      try {
        const { data: { tireReviews } } = await getRequest(`api/v1/admin/tire-models/review/${id}`);
        commit('setTireReview', tireReviews);
      } catch (e) {
        console.error(e);
      }
    },
    async setTireReviewDescription({
      commit, dispatch, rootGetters,
    }, { id, review }) {
      commit('setEditReviewRequestStatus', 'requested');
      try {
        const reqReview = {
          rating: review.rating,
          contentPlus: review.contentPlus,
          contentMinus: review.contentMinus,
          contentComment: review.contentComment,
          authorFio: review.authorFio,
          authorEmail: review.authorEmail,
          sort: review.sort,
          isActive: review.isActive,
        };
        await postRequest(`api/v1/admin/tire-models/review/${id}`, reqReview);
        commit('setTireReview', review);
        const rootPagination = rootGetters.TIRE_REVIEWS_PAGINATION;

        if (has(rootPagination, 'site.currentPage')) {
          dispatch('getTireReviewsList', {
            id: rootGetters.TIRE_MODEL.id,
            params: {
              page: rootPagination.site.currentPage,
              perPage: rootPagination.site.perPage,
            },
            from: 'site',
          });
        }

        if (has(rootPagination, 'yandex.currentPage')) {
          dispatch('getTireReviewsList', {
            id: rootGetters.TIRE_MODEL.id,
            params: {
              page: rootPagination.yandex.currentPage,
              perPage: rootPagination.yandex.perPage,
            },
            from: 'yandex',
          });
        }

        commit('setEditReviewRequestStatus', 'success');
      } catch (e) {
        commit('setEditReviewRequestStatus', 'faileds');
        console.error(e);
      }
    },
  },
  getters: {
    TIRE_REVIEW: (state) => state.tireReview,
    EDIT_REVIEW_REQUEST_STATUS: (state) => state.editReviewsRequestStatus,
  },
};
