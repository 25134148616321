import { postRequest } from '@/utils/apiHelper';
import Vue from 'vue';
import router from '@/router';

export default {
  state: () => ({
    isAuth: false,
    user: {},
    // eslint-disable-next-line no-useless-concat, max-len
    token: document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)')) ? document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)'))[2] : '',
  }),
  mutations: {
    setIsAuth(state) {
      state.isAuth = !!state.token;
    },
    setAuth(state, payload) {
      state.token = payload.authToken;
      Vue.$cookies.set('token_', payload.authToken);
    },
    removeAuth(state) {
      Vue.$cookies.remove('token_');
      state.token = null;
    },
  },
  actions: {
    async loginUserAction({ commit }, data) {
      try {
        const response = await postRequest('api/v1/admin/login', data);
        if (response.data.authToken) {
          commit('setAuth', response.data);
          commit('setIsAuth');
          router.push({
            name: 'Home',
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async logoutUserAction({ state, commit }) {
      if (state.isAuth) {
        commit('removeAuth');
        commit('setIsAuth');
      }
    },
  },
  getters: {
    USER: (state) => state.user,
    IS_AUTH: (state) => state.isAuth,
  },
};
